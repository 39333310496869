import React, { useState } from "react";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Collapse,
  Button,
  Typography,
  Rating,
  useTheme,
  useMediaQuery,
  Modal,
  TextField,
  Stack,
  styled,
  InputLabel,
  MenuItem,
  FormControl,
  Select
} from "@mui/material";
import Header from "components/Header";
import { useGetProductsQuery } from "state/api";
import CustomColumnMenu from "components/DataGridCustomColumnMenu";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Image } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: 'none',
  outline: 'none',
  borderRadius: '5px',
  boxShadow: 24,
  p: 4,
};

const CompetitionType = ({
  _id,
  name,
  description,
  price,
  rating,
  category,
  supply,
  stat,
}) => {
  const theme = useTheme();
  const [isExpanded, setIsExpanded] = useState(false);
  const navigate = useNavigate();

  const goView = (params) => {
    navigate('/competition/levels', { state: params });
  }

  return (
    <Card
      sx={{
        backgroundImage: "none",
        backgroundColor: theme.palette.background.alt,
        borderRadius: "0.55rem",
      }}
    >
      <CardContent style={{textAlign:'center'}} onClick={()=>goView({id: _id, name: name})}>
        {/* <Typography
          sx={{ fontSize: 14 }}
          color={theme.palette.secondary[700]}
          gutterBottom
        >
          {category}
        </Typography> */}
        <img
          src={`${process.env.REACT_APP_API_URL}/img/${name.replace(' ', '_').toLowerCase()}.png?w=164&h=164&fit=crop&auto=format`}
          alt={name}
          width={80}
          loading="lazy"
        />
        {/* <Typography variant="h5" component="div">
          {name}
        </Typography> */}
        {/* <Typography sx={{ mb: "1.5rem" }} color={theme.palette.secondary[400]}>
          ${Number(price).toFixed(2)}
        </Typography> */}
        {/* <Rating value={rating} readOnly /> */}

        {/* <Typography variant="body2">{description}</Typography> */}
      </CardContent>
      {/* <CardActions>
        <Button
          variant="primary"
          size="small"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          See More
        </Button>
      </CardActions> */}
      <Collapse
        in={isExpanded}
        timeout="auto"
        unmountOnExit
        sx={{
          color: theme.palette.neutral[300],
        }}
      >
        <CardContent>
          <Typography>id: {_id}</Typography>
          <Typography>Supply Left: {supply}</Typography>
          <Typography>
            Yearly Sales This Year: {stat.yearlySalesTotal}
          </Typography>
          <Typography>
            Yearly Units Sold This Year: {stat.yearlyTotalSoldUnits}
          </Typography>
        </CardContent>
      </Collapse>
    </Card>
  );
};

const CompetitionTypes = () => {
  const theme   = useTheme();
  const { data, isLoading } = useGetProductsQuery();
  const [open, setOpen] = useState(false);
  const isNonMobile = useMediaQuery("(min-width: 1000px)");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const defaultTheme = {
    backgroundColor: theme.palette.background.alt,
    color: theme.palette.text.primary
  }

  const [name,setName]           = useState('');
  const [status,setStatus]       = useState('');
  const [category,setCategory]   = useState('');
  const [sorting,setSorting]     = useState('');
  const [image,setImage]         = useState('');

  const statusList     = ['Deactive','Active'];
  const categoryList   = ['Cricket','Football','Carrom'];

  const saveData = ()=>{
    console.log(`name     : ${name}`)
    console.log(`status   : ${status}`)
    console.log(`category : ${category}`)
    console.log(`sorting  : ${sorting}`)
    console.log(`image    : ${image.name}`)

    /* clear state data */
    setName('')
    setStatus('')
    setCategory('')
    setSorting('')
    setImage('')
  }

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="Competition Types" subtitle="See your list of competition type." />
      
      <Box sx={{ width: '100%', textAlign: "right"}}>
        <Button onClick={handleOpen} sx={{...defaultTheme,fontWeight: 'bold'}}>Add</Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography level="h1" sx={{ textAlign: 'center',mb:3,fontSize: '35px',fontWeight: 'bold' }}>Add Competition</Typography>
  
              <TextField id="outlined-basic" label="Name" variant="outlined" sx={{width: '100%',mb: 2}} value={name} onChange={(e)=>setName(e.target.value)} />
              
              <Stack
                direction="row"
                spacing={2}
                sx={{
                  justifyContent: "center",
                  alignItems: "center",
                  mb: 2
                }}
              > 

              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">status</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={status}
                  label="Status"
                  onChange={(e)=>setStatus(e.target.value)}
                >
                  {
                    statusList.map((item,index)=>{
                      return(
                        <MenuItem key={index} value={item}>{item}</MenuItem>
                      )
                    })
                  }
                </Select>
              </FormControl>

              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Category</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={category}
                  label="Category"
                  onChange={(e)=>setCategory(e.target.value)}
                >
                  {
                    categoryList.map((item,index)=>{
                      return(
                        <MenuItem key={index} value={item}>{item}</MenuItem>
                      )
                    })
                  }
                </Select>
              </FormControl>

              </Stack>

              <TextField id="outlined-basic" label="Sorting" variant="outlined" type="number" sx={{width: '100%',mb: 2}} value={sorting} onChange={(e)=>setSorting(e.target.value)} />

              <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={<CloudUploadIcon />}
                sx={{mb: 2,width: '100%'}}
              >
                Upload files
                <VisuallyHiddenInput
                  type="file"
                  onChange={(e) => setImage(e.target.files[0])}
                />
              </Button>

              <Button variant="contained" sx={{width: "100%",backgroundColor: 'blue',color: 'white',fontWeight: 'bold',mb: 2}} onClick={saveData}>Save</Button>
              <Button variant="contained" sx={{width: "100%",backgroundColor: 'red',color: 'white',fontWeight: 'bold'}} onClick={()=>setOpen(false)}>Close</Button>
          </Box>
        </Modal>
      </Box>


      {data || !isLoading ? (
        <Box
          mt="20px"
          display="grid"
          gridTemplateColumns="repeat(4, minmax(0, 1fr))"
          justifyContent="space-between"
          rowGap="20px"
          columnGap="1.33%"
          sx={{
            "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
          }}
        >
          {data.map(
            ({
              _id,
              name,
              description,
              price,
              rating,
              category,
              supply,
              stat,
            }) => (
              <CompetitionType
                key={_id}
                _id={_id}
                name={name}
                description={description}
                price={price}
                rating={rating}
                category={category}
                supply={supply}
                stat={stat}
              />
            )
          )}
        </Box>
      ) : (
        <>Loading...</>
      )}
    </Box>
  );
};

export default CompetitionTypes;