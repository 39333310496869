import React from "react";
import styled from "styled-components";


const InputField = ({ icon, placeholder, type, onChange }) => {
  return (
    <InputWrapper>
      <InputIcon loading="lazy" src={icon} alt={`${placeholder} icon`} />
      <Input type={type} placeholder={placeholder} aria-label={placeholder} onChange={onChange}/>
    </InputWrapper>
  );
};

const InputWrapper = styled.div`
  border-radius: 4px;
  display: flex;
  gap: 19px;
  white-space: nowrap;
  text-transform: uppercase;
  line-height: 1;
  padding: 13px 12px;
  border: 1px solid #2a0a0a;
  margin-bottom: 20px;

  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const InputIcon = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 20px;
`;

const Input = styled.input`
  flex-grow: 1;
  width: 233px;
  background: transparent;
  border: none;
  color: #0e0b0b;
  font: inherit;
  text-transform: none;

  &::placeholder {
    color: #350e0e;
    opacity: 1;
  }

  &:focus {
    outline: none;
  }
`;

export default InputField;