import { CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { themeSettings } from "theme";

import Layout from "scenes/layout";
import Dashboard from "scenes/dashboard";
import CompetitionTypes from "scenes/competitiontypes";
import Customers from "scenes/customers";
import User from "scenes/user";
import Transactions from "scenes/transactions";
import Geography from "scenes/geography";
import Overview from "scenes/overview";
import Daily from "scenes/daily";
import Monthly from "scenes/monthly";
import Breakdown from "scenes/breakdown";
import Admin from "scenes/admin";
import Performance from "scenes/performance";
import Login from "scenes/login";
import EditCustomer from "scenes/customers/EditCustomer";
import ReleaseMessage from "scenes/ReleaseMessage";
import NotFound from "scenes/NotFound";
import Teachers from "scenes/teachers";
import CompetitionLevels from "scenes/competitiontypes/CompetitionLevels";
import CompetitionLevelUsers from "scenes/competitiontypes/CompetitionLevelUsers";


function App() {
  const mode = useSelector((state) => state.global.mode);
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  
  return (
    <div className="app">
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            
              {isAuthenticated ? (
                <Routes>
                  <Route element={<Layout />}>
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/competitiontypes" element={<CompetitionTypes />} />
                    <Route path="/competition/levels" element={<CompetitionLevels />} />
                    <Route path="/competition/level/users/" element={<CompetitionLevelUsers />} />
                    <Route path="/customers" element={<Customers />} />
                    <Route path="/edit-customer" element={<EditCustomer />} />
                    <Route path="/teachers" element={<Teachers />} />
                    <Route path="/transactions" element={<Transactions />} />
                    <Route path="/geography" element={<Geography />} />
                    <Route path="/overview" element={<Overview />} />
                    <Route path="/daily" element={<Daily />} />
                    <Route path="/monthly" element={<Monthly />} />
                    <Route path="/breakdown" element={<Breakdown />} />
                    <Route path="/admin" element={<Admin />} />
                    <Route path="/performance" element={<Performance />} />
                    <Route path="/user" element={<User />} />
                    <Route path="*" element={<NotFound />} />
                  </Route>
                </Routes>
              ) : (
                <Routes>
                  <Route path="/" element={<ReleaseMessage/>} />
                  <Route path="/securelogin" element={<Login/>} />
                  <Route path="*" element={<NotFound />} />
                </Routes>
              )}
            
            
          </ThemeProvider>
        </BrowserRouter>

    </div>
  );
}

export default App;