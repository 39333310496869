// src/state/authSlice.js
import { createSlice } from "@reduxjs/toolkit";
import AuthUser from "components/AuthUser";

const initialState = {
    authToken: sessionStorage.getItem('authToken') || null,
    isAuthenticated: sessionStorage.getItem('authToken') ? true : false,
  };

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state, action) => {
      // Save token to localStorage
      sessionStorage.setItem('authToken', action.payload.authToken);
      sessionStorage.setItem('user', action.payload.userData);

      state.isAuthenticated = true;
      state.authToken = action.payload.authToken;
    },
    logout: (state) => {
      sessionStorage.clear();
      state.isAuthenticated = false;
      state.authToken = null;
    },
  },
});

export const { login, logout } = authSlice.actions;
export default authSlice.reducer;
