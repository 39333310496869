import React,{useState} from "react";
import { toast, ToastContainer } from "react-toastify";
import { useCreatePostMutation, useDeleteTeacherMutation } from "../../state/api";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Collapse,
  Button,
  Typography,
  Rating,
  useTheme,
  useMediaQuery,
  Modal,
  TextField,
  Stack,
  styled,
  InputLabel,
  MenuItem,
  FormControl,
  Select
} from "@mui/material";
import { useGetAdminsQuery } from "state/api";
import { DataGrid } from "@mui/x-data-grid";
import Header from "components/Header";
import CustomColumnMenu from "components/DataGridCustomColumnMenu";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: 'none',
  outline: 'none',
  borderRadius: '5px',
  boxShadow: 24,
  p: 4,
};

const Admin = () => {
  const theme = useTheme();
  const [deleteTeacher] = useDeleteTeacherMutation();
  const { data, isLoading } = useGetAdminsQuery();
  console.log(data);
  const [createPost] = useCreatePostMutation();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const defaultTheme = {
    backgroundColor: theme.palette.background.alt,
    color: theme.palette.text.primary
  };

  const [name,setName]               = useState('')
  const [email,setEmail]             = useState('')
  const [phone,setPhone]             = useState('')
  const [role,setRole]               = useState('')
  const [password,setPassword]       = useState('')
  const [status,setStatus]           = useState('')
  const [expertise,setExpertise]     = useState('')
  const [country,setCountry]         = useState('')
  const [image,setImage]             = useState('')
  const [message,setMessage]         = useState('')

  const statusList     = ['Deactive','Active'];
  const roleList = ['user','teacher','admin','superadmin'];
  const countryList    = ['Bangladesh','India','America','Russsia'];

  const saveData = async()=>{
    try {
      const result = await createPost({ name, email, phone, role, password, status, expertise, country }).unwrap();
      setMessage(true);
      console.log("Post created:", result);
    } catch (error) {
      setMessage(false);
      console.error("Failed to create post:", error);
    }
    console.log(`name          : ${name}`)
    console.log(`email         : ${email}`)
    console.log(`phone         : ${phone}`)
    console.log(`role          : ${role}`)
    console.log(`password      : ${password}`)
    console.log(`status        : ${status}`)
    console.log(`expertise     : ${expertise}`)
    console.log(`country       : ${country}`)
    console.log(`image         : ${image.name}`)

    /* clear state data */
    setName('')
    setEmail('')
    setPhone('')
    setRole('')
    setPassword('')
    setStatus('')
    setExpertise('')
    setCountry('')
    setImage('')
  }
  
  const handleDeleteClick = async (e) => {
    if ( window.confirm("Are you sure to delete?") ) {
      const resp = await deleteTeacher(e._id);
            const {status, message} = resp;
            if ( status == 'success' ){
              toast.success(message);
            } else {
              toast.error(message);
            }
    }
    return false;
  }
  
  const columns = [
    // {
    //   field: "_id",
    //   headerName: "ID",
    //   flex: 1,
    // },
    {
      field: "name",
      headerName: "Name",
      flex: 0.5,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
    },
    {
      field: "phone",
      headerName: "Phone Number",
      flex: 0.5,
      // renderCell: (params) => {
      //   return params.value.replace(/^(\d{3})(\d{3})(\d{4})/, "($1)$2-$3");
      // },
    },
    {
      field: "country",
      headerName: "Country",
      flex: 0.4,
    },
    {
      field: "expertise",
      headerName: "Expertise",
      flex: 1,
    },
    {
      field: "role",
      headerName: "Role",
      flex: 0.5,
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => {
        return (
          <Box>
            {/* <Button variant="text" color="secondary" onClick={() => handleViewClick(params.row)}>
              View
            </Button> */}
            <Button variant="text" color="secondary" onClick={handleOpen}>
              Edit
            </Button>
            <Button variant="text" color="secondary" onClick={() => handleDeleteClick(params.row)}>
              Delete
            </Button>
          </Box>
        );
      },
    },
  ];

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="ADMINS" subtitle="Managing admins and list of admins" />

      <Box sx={{ width: '100%', textAlign: "right"}}>
        <Button onClick={handleOpen} sx={{...defaultTheme,fontWeight: 'bold'}}>Add</Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography level="h1" sx={{ textAlign: 'center',fontSize: '35px',fontWeight: 'bold' }}>Add Teacher</Typography>

            <Typography level="h1" sx={{ textAlign: 'center',mb:2,fontSize: '15px',fontWeight: 'bold',color: message === true ? 'green' : ((message === false) ? 'red' : '') }}>
              {
                message === true ? 'Save Successfully.' : ((message === false) ? 'Save Failed.' : '')
              }
            </Typography>

            <TextField id="outlined-basic-1" label="Name" variant="outlined" sx={{width: '100%',mb: 2}} value={name} onChange={(e)=>setName(e.target.value)} />

            <TextField id="outlined-basic-2" label="Email" type="email" variant="outlined" sx={{width: '100%',mb: 2}} value={email} onChange={(e)=>setEmail(e.target.value)} />

            <TextField id="outlined-basic-3" label="Phone" variant="outlined" sx={{width: '100%',mb: 2}} value={phone} onChange={(e)=>setPhone(e.target.value)} />

            <Stack
              direction="row"
              spacing={2}
              sx={{
                justifyContent: "center",
                alignItems: "center",
                mb: 2
              }}
            > 
              <TextField id="outlined-basic-4" label="Expertise In" variant="outlined" sx={{width: '50%'}} value={expertise} onChange={(e)=>setExpertise(e.target.value)} />

              <TextField id="outlined-basic-5" label="Password" type="password" variant="outlined" sx={{width: '50%'}} value={password} onChange={(e)=>setPassword(e.target.value)} />
            </Stack>

            <Stack
              direction="row"
              spacing={2}
              sx={{
                justifyContent: "center",
                alignItems: "center",
                mb: 2
              }}
            > 

            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">status</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={status}
                label="Status"
                onChange={(e)=>setStatus(e.target.value)}
              >
                {
                  statusList.map((item,index)=>{
                    return(
                      <MenuItem key={index} value={item}>{item}</MenuItem>
                    )
                  })
                }
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Role</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={role}
                label="Role"
                onChange={(e)=>setRole(e.target.value)}
              >
                {
                  roleList.map((item,index)=>{
                    return(
                      <MenuItem key={index} value={item}>{item}</MenuItem>
                    )
                  })
                }
              </Select>
            </FormControl>
            </Stack>


            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Country</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={country}
                label="Country"
                onChange={(e)=>setCountry(e.target.value)}
                sx={{mb:2}}
              >
                {
                  countryList.map((item,index)=>{
                    return(
                      <MenuItem key={index} value={item}>{item}</MenuItem>
                    )
                  })
                }
              </Select>
            </FormControl>

            <Button
              component="label"
              role={undefined}
              variant="contained"
              tabIndex={-1}
              startIcon={<CloudUploadIcon />}
              sx={{mb: 2,width: '100%'}}
            >
              Upload files
              <VisuallyHiddenInput
                type="file"
                onChange={(e) => setImage(e.target.files[0])}
              />
            </Button>

            <Button variant="contained" sx={{width: "100%",backgroundColor: 'blue',color: 'white',fontWeight: 'bold',mb: 2}} onClick={saveData}>Save</Button>
            <Button variant="contained" sx={{width: "100%",backgroundColor: 'red',color: 'white',fontWeight: 'bold'}} onClick={()=>setOpen(false)}>Close</Button>
          </Box>
        </Modal>
      </Box>

      <Box
        mt="40px"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          loading={isLoading || !data}
          getRowId={(row) => row._id}
          rows={data || []}
          columns={columns}
          components={{
            ColumnMenu: CustomColumnMenu,
          }}
        />
        

        

      </Box>
    </Box>
  );
};

export default Admin;
