import React from "react";
import styled from "styled-components";



const LoginButton = ({ children }) => {
  return <StyledButton type="submit">{children}</StyledButton>;
};

const StyledButton = styled.button`
  border-radius: 4px;
  background-color: #150e0e;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
  margin-top: 43px;
  font-size: 16px;
  color: #f3f5fb;
  font-weight: 600;
  white-space: nowrap;
  text-transform: uppercase;
  line-height: 1;
  padding: 13px 70px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #ece3e3;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(33, 72, 192, 0.5);
  }

  @media (max-width: 991px) {
    margin-top: 40px;
    white-space: initial;
    padding: 13px 20px;
    width: 100%;
  }
`;

export default LoginButton;