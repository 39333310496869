import React,{useState,useEffect,memo} from "react";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Collapse,
  Button,
  Typography,
  Rating,
  useTheme,
  useMediaQuery,
  Modal,
  TextField,
  Stack,
  styled,
  InputLabel,
  MenuItem,
  FormControl,
  Select
} from "@mui/material";
import { useGetCustomersQuery } from "state/api";
import Header from "components/Header";
import { DataGrid } from "@mui/x-data-grid";
import {useNavigate,useLocation} from 'react-router-dom'
import CustomColumnMenu from "components/DataGridCustomColumnMenu";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: 'none',
  outline: 'none',
  borderRadius: '5px',
  boxShadow: 24,
  p: 4,
};

const EditCustomer = () => {
  const theme    = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const { data, isLoading } = useGetCustomersQuery();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const defaultTheme = {
    backgroundColor: theme.palette.background.alt,
    color: theme.palette.text.primary
  };

  const [name,setName]         = useState('')
  const [phone,setPhone]       = useState('')
  const [password,setPassword] = useState('')
  const [email,setEmail]       = useState('')
  const [status,setStatus]     = useState('')
  const [country,setCountry]   = useState('')
  const [image,setImage]       = useState('')

  useEffect(()=>{
    const {name,email,city,country,occupation,phoneNumber} = location.state;
    setName(name)
    setEmail(email)
    setPhone(phoneNumber)
  },[])

  const statusList     = ['Deactive','Active'];
  const occupationList = ['Doctor','Engineer','Army'];
  const countryList    = ['Bangladesh','India','America','Russsia'];

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="CUSTOMER" subtitle="Edit Customer Form" />

      <Box
        mt="40px"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >

      <Box>
        <TextField label="Name" sx={{width: '100%',mb: 2}} value={name} onChange={(e)=>setName(e.target.value)} />

        <TextField label="Email" type="email" sx={{width: '100%',mb: 2}} value={email} onChange={(e)=>setEmail(e.target.value)} />

        <Stack
          direction="row"
          spacing={2}
          sx={{
            justifyContent: "center",
            alignItems: "center",
            mb: 2
          }}
        > 
          <TextField label="Phone" sx={{width: '50%'}} value={phone} onChange={(e)=>setPhone(e.target.value)} />

          <TextField label="Password" type="password" sx={{width: '50%'}} value={password} onChange={(e)=>setPassword(e.target.value)} />
        </Stack>

        <Stack
          direction="row"
          spacing={2}
          sx={{
            justifyContent: "center",
            alignItems: "center",
            mb: 2
          }}
        > 

        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">status</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={status}
            label="Status"
            onChange={(e)=>setStatus(e.target.value)}
          >
            {
              statusList.map((item,index)=>{
                return(
                  <MenuItem key={index} value={item}>{item}</MenuItem>
                )
              })
            }
          </Select>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Country</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={country}
            label="Country"
            onChange={(e)=>setCountry(e.target.value)}
          >
            {
              countryList.map((item,index)=>{
                return(
                  <MenuItem key={index} value={item}>{item}</MenuItem>
                )
              })
            }
          </Select>
        </FormControl>
        </Stack>

        <Button
          component="label"
          role={undefined}
          variant="contained"
          tabIndex={-1}
          startIcon={<CloudUploadIcon />}
          sx={{mb: 2,width: '100%'}}
        >
          Upload files
          <VisuallyHiddenInput
            type="file"
            onChange={(e) => setImage(e.target.files[0])}
          />
        </Button>

        <Stack
          direction="row"
          spacing={2}
          sx={{
            justifyContent: "center",
            alignItems: "center",
            mb: 2
          }}
        > 
        <Button variant="contained" sx={{width: "100%",backgroundColor: 'blue',color: 'white',fontWeight: 'bold',mb: 2}}>Save</Button>
        <Button variant="contained" sx={{width: "100%",backgroundColor: 'red',color: 'white',fontWeight: 'bold'}} onClick={()=>navigate('/customers')}>Back</Button>

        </Stack>
      </Box>
        
      </Box>
    </Box>
  )
}

export default memo(EditCustomer)