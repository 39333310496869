import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const api = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_API_URL }),
  reducerPath: "adminApi",
  tagTypes: [
    "User",
    "Products",
    "Customers",
    "Transactions",
    "Geography",
    "Sales",
    "Admins",
    "Performance",
    "Dashboard",
    "Teacher"
  ],
  endpoints: (build) => ({
    getUser: build.query({
      query: (id) => `general/user/${id}`,
      providesTags: ["User"],
    }),
    getProducts: build.query({
      query: () => "client/products",
      providesTags: ["Products"],
    }),
    getCustomers: build.query({
      query: () => "client/customers",
      providesTags: ["Customers"],
    }),
    getTransactions: build.query({
      query: ({ page, pageSize, sort, search }) => ({
        url: "client/transactions",
        method: "GET",
        params: { page, pageSize, sort, search },
      }),
      providesTags: ["Transactions"],
    }),
    getGeography: build.query({
      query: () => "client/geography",
      providesTags: ["Geography"],
    }),
    getSales: build.query({
      query: () => "sales/sales",
      providesTags: ["Sales"],
    }),
    getAdmins: build.query({
      query: () => "management/admins",
      providesTags: ["Admins"],
    }),
    getUserPerformance: build.query({
      query: (id) => `management/performance/${id}`,
      providesTags: ["Performance"],
    }),
    getDashboard: build.query({
      query: () => "general/dashboard",
      providesTags: ["Dashboard"],
    }),
    createPost: build.mutation({
      query: (newPost) => ({
        url: "api/register",
        method: "POST",
        body: newPost,
      }),
    }),

    getTeacher: build.query({
      query: () => "general/teachers",
      providesTags: ["Teacher"],
    }),
    createTeacher: build.mutation({
      query: (newPost) => ({
        url: "general/teacher/add",
        method: "POST",
        body: newPost,
      }),
      invalidatesTags: ["Teacher"],
    }),
    updateTeacher: build.mutation({
      query: (formData) => ({
        url: `general/teacher/update/${formData._id}`,
        method: "PUT",
        body: formData,
      }),
      invalidatesTags: ["Teacher"],
    }),
    deleteTeacher: build.mutation({
      query: (id) => ({
        url: `general/teacher/delete/${id}`,
        method: "DELETE",
      }),
      // After deleting, invalidate user data or other cached data
      invalidatesTags: ["Teacher"],
    }),
  }),
});

export const {
  useGetUserQuery,
  useGetProductsQuery,
  useGetCustomersQuery,
  useGetTransactionsQuery,
  useGetGeographyQuery,
  useGetSalesQuery,
  useGetAdminsQuery,
  useGetUserPerformanceQuery,
  useGetDashboardQuery,
  useCreatePostMutation,
  useGetTeacherQuery,
  useCreateTeacherMutation,
  useUpdateTeacherMutation,
  useDeleteTeacherMutation
} = api;
