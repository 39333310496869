import React from 'react';
import './style.css'; // Make sure to create this CSS file

const NotFound = () => {
  return (
    <div className="release-message">
      <div className="release-content">
        <h1 className="release-title">404</h1>
        <p className="release-text">
          Sorry, page not found you are looking for!
        </p>
        <a href='/' className="notify-button">Go Home</a>
      </div>
    </div>
  );
};

export default NotFound;
