// src/pages/Login.tsx
import React, { useState } from 'react';
import styled from "styled-components";
import axios from 'axios';
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { login } from "../../state/authSlice";
import InputField from './InputField';
import LoginButton from './LoginButton';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    setError('');
    if ( email === '' || password === '') {
      setError("Please enter email and password!");
      return false;
    }

    try {
      const API_URL = process.env.REACT_APP_API_URL;
      await axios.post(API_URL+'/api/login', { email, password }).then( (response)=>{
        console.log(response);
        if ( response.data.status=='success' ){
          const { authToken } = response.data.data.token;
          const { userData } = response.data.data;
          dispatch(login({authToken: authToken, userData: userData}));
          navigate('/dashboard');  // Redirect to admin page
        } else {
          setError(response.data.message);
        }
        
      }, (e)=>{
        console.log("Error: ", e);
      });
      

     
    } catch (err) {
      setError('Invalid credentials');
    }
  };

  return (
      <LoginContainer>
      <LoginWrapper>
        <Logo src='logo.png'></Logo>
        <Error>{error}</Error>
        <LoginForm onSubmit={handleSubmit}>
          <InputField
            icon="https://cdn.builder.io/api/v1/image/assets/TEMP/57504037d7e6b3838ed793dd36d04799dd38f02f8e5105ccabc9e9c37e72edb4?placeholderIfAbsent=true&apiKey=bff3ce9d61344059b315cf9ffec1ae9b"
            placeholder="Email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <InputField
            icon="https://cdn.builder.io/api/v1/image/assets/TEMP/47e1946ab5690657a70c7581182fa78d733a9e0928efd9d0c229f2aaa93d4f82?placeholderIfAbsent=true&apiKey=bff3ce9d61344059b315cf9ffec1ae9b"
            placeholder="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <LoginButton>login</LoginButton>
          {/* <ForgotPassword>Forgot password?</ForgotPassword> */}
        </LoginForm>
      </LoginWrapper>
    </LoginContainer>
  );
};

const LoginContainer = styled.main`
  //background-color: #2148c0;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  color: #171212;
  text-align: center;
  align-items: center;
  justify-content: center;
  font: 300 14px Montserrat, sans-serif;
  margin-top: 100px;
`;
const Error = styled.div`
  color: red;
  padding: 0 5px;
`;
const Success = styled.div`
  color: green;
  padding: 0 5px;
`;


const LoginWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: center;
  padding: 30px 10px 80px;
  background-color: #48aaad;
  @media (max-width: 991px) {
    max-width: 100%;
    padding: 100px 20px;
  }
`;

const BackgroundImage = styled.img`
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
`;

const LoginForm = styled.form`
  position: relative;
  display: flex;
  margin-bottom: -32px;
  width: 300px;
  max-width: 100%;
  flex-direction: column;

  @media (max-width: 991px) {
    margin-bottom: 10px;
  }
`;

const Logo = styled.img`
  aspect-ratio: 1.21;
  object-fit: contain;
  object-position: center;
  width: 119px;
  align-self: center;
  max-width: 100%;
  margin-bottom: 71px;
  border-radius: 30px;
  @media (max-width: 991px) {
    margin-bottom: 40px;
  }
`;

const ForgotPassword = styled.a`
  font-size: 16px;
  font-weight: 500;
  align-self: flex-end;
  margin-top: 11px;
  text-decoration: none;
  color: inherit;
  cursor: pointer;
`;

export default Login;