import React from 'react';
import './style.css'; // Make sure to create this CSS file

const ReleaseMessage = () => {
  return (
    <div className="release-message">
      <div className="release-content">
        <h1 className="release-title">Welcome to Orbit Competition!</h1>
        <p className="release-text">
          Our app is launching on <strong>25th November</strong>! Be the first to experience it.
        </p>
        <button className="notify-button">Notify Me</button>
      </div>
    </div>
  );
};

export default ReleaseMessage;
